define("discourse/plugins/kistaKoord/kista-koord/controllers/koord-manage-invite", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("email"), (_obj = {
    keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    onShow() {
      this.setProperties({
        // group: this.fullgroupname,
        group: window.xdata.full,
        link: "https://lnw.kinderstark.nrw/invites/" + window.xdata.key,
        email: window.xdata.email,
        // validto: dt.getFullYear()+"-"+(x=>x>9?x:"0"+x)(dt.getMonth()+1)+"-"+(x=>x>9?x:"0"+x)(dt.getDate()),
        vdt: window.xdata.validto,
        is_sim: window.xdata.invid == -99
      });
      this.setProperties({
        server: this.get("servers.firstObject.value")
      });
    },
    genpw() {
      return Math.random().toString(36).slice(-8);
    },
    submitDisabled(email) {
      return (0, _utils.isEmpty)(email) || !/^[-._+a-z0-9]+@[-._a-z0-9]{2,}\.[a-z]{2,}$/i.test(email); // || !/^2[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(validto);
    },
    actions: {
      resend() {
        const btnTxt = this.buttonText ? ` label="${this.buttonText}"` : "";
        console.log(this.message);
        let validto = this.vdt instanceof moment ? this.vdt.toDate() : new Date(this.vdt);
        this.retfunc({
          email: this.email,
          message: this.message,
          validto: validto
        });
        // this.toolbarEvent.addText(
        //   `[wrap=bbb Titel="${this.title}"${btnTxt} pp="${this.genpw()}-${this.genpw()}"${this.hasOwnProperty("server")&&this.server!=1?' sr="'+this.server+'"':''}][/wrap]`
        // ); // mobileIframe="${ this.mobileIframe }" desktopIframe="${this.desktopIframe}"
        this.send("closeModal");
      },
      extend() {
        this.send("closeModal");
      },
      execsim() {
        this.execsimfunc();
        this.send("closeModal");
      },
      delete() {
        this.delfunc();
        this.send("closeModal");
      },
      cancel() {
        this.send("closeModal");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "submitDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "submitDisabled"), _obj)), _obj)));
});