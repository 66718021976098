define("discourse/plugins/kistaKoord/initializers/kista-koord", ["exports", "discourse/lib/plugin-api", "discourse/lib/click-track", "discourse/lib/show-modal", "discourse-common/lib/icon-library", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/routing/route"], function (_exports, _pluginApi, _clickTrack, _showModal, _iconLibrary, _ajax, _ajaxError, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  const { h } = require('virtual-dom');
  const { attachAdditionalPanel } = require('discourse/widgets/header');
  */
  const h = require("virtual-dom").h;
  function ct(url) {
    console.log("click-track", url, (0, _clickTrack.shouldOpenInNewTab)(url));
  } /* https www.spiegel.de true, localhost:3000 false, www.kinderstark.nrw true */

  const tmp_invitemodal = (group, submitSimulation, vdt, send, cancel) => {
    var hbs = $(`<div class="tmpmodal koord-invite">
 <div class="tmpmodaltitle">Neues Mitglied einladen</div>
 <div class="koordinviteform">
   <div class="koord-invite-subtitle tmpmodalmanual" style="margin-top:5px">Sende einen Einladungslink für den Beitritt zur LernnetzwApp<br>als Mitglied der Gruppe <b>${group}</b> an:</div>
   <div class="insert-bbb-input bbb-button-text">
     <label>Email-Adresse:</label>
     <input type=text id="kiet" placeholder="beispiel@adresse.de">
   </div>
   <div class="koord-invite-simulationinfo" style="display:${submitSimulation ? "inherit" : "none"}">Durch das "!"-Zeichen in der Adresse wird die Einladung nur simuliert und es wird keine Email versandt.<br><br>Es wird lediglich ein simulierter Gast-Account erscheinen, der nach einer Stunde verschwindet.</div>
   <div>
     <label>Begleitende Nachricht (optional):</label>
     <textarea id="kimt" style="width:100%"></textarea>
   </div>
 </div>
 <div class="tmpmodalbuttons modal-footer">
  <button class="btn-primary tmpmodalok" disabled=submitDisabled>Absenden</button>
  <button class="btn-danger tmpmodalcancel" data-onclick="cancel">Abbrechen</button>
 </div>
</div>
`);
    hbs.find("button.tmpmodalcancel").on("click", () => hbs.remove());
    let siminf = hbs.find(".koord-invite-simulationinfo"),
      submitbtn = hbs.find("button.tmpmodalok");
    hbs.find("#kiet").on("input", e => {
      siminf.css("display", /^\s*!/.test(e.target.value) ? "inherit" : "none");
      submitbtn[0].disabled = !/^\s*[-a-zäöüß_.+!]+@[-a-zäöüß_.]+\.\w{2,}\s*$/i.test(e.target.value);
    });
    submitbtn.on("click", e => {
      let grouppathname = window.location.pathname;
      let email = /^\s*(\S*)\s*$/.exec(hbs.find("#kiet").val())[1],
        message = hbs.find("#kimt").val();
      let validto = new Date(new Date() * 1 + 180 * 24 * 3600 * 1000).toISOString();
      $.ajax("/kista-koord/invite.json", {
        method: "POST",
        data: {
          email: email,
          validto: validto,
          message: message
        },
        success: a => {
          console.log("invitesuccess", a);
          //require("discourse/lib/url").default.routeTo("/");
          require("discourse/lib/url").default.routeTo(grouppathname);
        }
      });
      hbs.remove();
    });
    $("body").append(hbs);
  };
  const tmp_manageinvitemodal = (group, email, link, message, vdt, is_sim, execsim, resend, fdelete, cancel) => {
    var hbs = `<div class="koord-invite">
<div>kista_koord.manageinvite.title</div>
<div class="koordinviteform">
  <div class="koord-invite-subtitle">Einladung für den Beitritt zur LernnetzwApp<br>als Mitglied der Gruppe <b>%{group}${group}</b></div>
  <div class="koord-invite-subtitle">an die Email-Adresse:</div>
  <div class="koord-invite-email">${email}</div>
  <div class="koord-invite-subtitle">Einladungslink:</div>
  <div class="koord-invite-link">${link}</div>
  <div>
    <label>Begleitende Nachricht (optional):</label>
    <textarea id="kmta">${message}</textarea>
  </div>
  <div class="koord-invite-mansim" style="display:${is_sim ? "inherit" : "none"}">
    <div><label>Simulierte Einladung:</label></div>
    <div><button class="btn-primary" onclick="execsim()">Virtuell einlösen</button></div>
  </div>
</div>
</div>

<div class="modal-footer">
<button class="btn-primary" onclick="resend()">Erneut absenden</button>
<button class="btn-danger" label="kista_koord.manageinvite.delete" onclick="fdelete()">Löschen</button>
<button class="btn-danger" label="kista_koord.manageinvite.cancel" onclick="cancel()">Abbrechen</button>
</div>
`;
  };
  function groupcoord(api) {
    let grouppathname = window.location.pathname;
    let shortdt = n => {
      var dt = new Date(1e3 * n);
      return dt.getDate() + ". " + "Jan,Feb,Mär,Apr,Mai,Jun,Jul,Aug,Sep,Okt,Nov,Dez".split(",")[dt.getMonth()] + ".";
    };
    const currentUser = api.getCurrentUser();
    const siteSettings = api.container.lookup("site-settings:main");
    window.xdata = {
      full: currentUser.primary_group_name
    };
    $(".koordadd,.koordtt,.koordth,.koordtc,.koordpendingtr,.koordinactivetr").remove();
    let invitemodal = e => {
      var group = xdata.full,
        submitSimulation = null,
        vdt = null,
        send = null,
        cancel = null;
      tmp_invitemodal(group, submitSimulation, vdt, send, cancel);
      /*
              showModal("koord-invite").setProperties({
                  // kewl: this.get("toolbarEvent"),
                  // data: xdata,
                  retfunc: x=>{
                    $.ajax("/kista-koord/invite.json",{method:"POST",data:{email:x.email,validto:x.validto.toJSON(),message:x.message},success:a=>{
                      console.log("invitesuccess",a);
                      require("discourse/lib/url").default.routeTo("/");
                      require("discourse/lib/url").default.routeTo(grouppathname);
                    }})
                  }
                });
      */
    };
    let manageinvitemodal = (id, e) => {
      window.xdata.invid = id;
      $.ajax("/kista-koord/getinvite.json", {
        method: "POST",
        data: {
          id: id
        },
        success: x => {
          window.xdata.key = x.result.invite_key;
          window.xdata.email = x.result.email;
          window.xdata.message = x.result.custom_message;
          window.xdata.validto = x.result.expires_at;
          (0, _showModal.default)("koord-manage-invite").setProperties({
            // kewl: this.get("toolbarEvent"),
            // data: xdata,
            retfunc: x => {
              $.ajax("/kista-koord/editinvite.json", {
                method: "POST",
                data: {
                  email: x.email,
                  validto: x.validto.toJSON(),
                  message: x.message
                },
                success: a => {
                  console.log("editinvitesuccess", a);
                  require("discourse/lib/url").default.routeTo("/");
                  require("discourse/lib/url").default.routeTo(grouppathname);
                }
              });
            },
            delfunc: x => {
              $.ajax("/kista-koord/editinvite.json", {
                method: "POST",
                data: {
                  delete_id: id
                },
                success: a => {
                  console.log("deletesuccess", a);
                  require("discourse/lib/url").default.routeTo("/");
                  require("discourse/lib/url").default.routeTo(grouppathname);
                }
              });
            },
            execsimfunc: x => {
              $.ajax("/kista-koord/editinvite.json", {
                method: "POST",
                data: {
                  execsim: id
                },
                success: a => {
                  console.log("execsimsuccess", a);
                  require("discourse/lib/url").default.routeTo("/");
                  require("discourse/lib/url").default.routeTo(grouppathname);
                }
              });
            }
          });
        }
      });
    };
    let suspendmodal = (id, name, isactive, e) => {
      window.xdata.name = name;
      window.xdata.isactive = isactive;
      (0, _showModal.default)("koord-suspend").setProperties({
        // kewl: this.get("toolbarEvent"),
        // data: xdata,
        retfunc: x => {
          console.log("rf", x);
          console.log(id);
          let dt;
          if (id && id != "Simulierter Gast") {
            dt = x ? {
              activate: id
            } : {
              deactivate: id
            };
          } else {
            dt = x ? {
              activate_sim: 0
            } : {
              deactivate_sim: 0
            };
          }
          console.log("dddd", id, dt);
          $.ajax("/kista-koord/manage.json", {
            method: "POST",
            data: dt,
            success: a => {
              console.log("activatesuccess", a);
              // window.location.reload();
              require("discourse/lib/url").default.routeTo("/");
              require("discourse/lib/url").default.routeTo(grouppathname);
              // require("discourse/lib/url").refresh();
              // Route.refresh();
            }
          });
        }
      });
    };
    $("ul.group-nav.nav-pills").append($('<li class="koordadd">').append($(`<button class='kista-koord btn'>${(0, _iconLibrary.iconHTML)("user-plus")} Einladung erstellen</button>`).on("click", invitemodal)));
    console.log("COUNT USERNAMES", $("table.group-members tr").find("div[data-username]").length);
    let updatelist = r => {
      $("div.group-members div.directory-table__row").find("div:last").addClass("koorddeactivate");
      if (r.simuser && r.simuser.active && !$("div.group-members div.directory-table__row.simuser").length) {
        let $sh = simuserhtml();
        $sh.appendTo($("div.group-members>div.directory-table__body"));
        $sh.find("button.kista-koord2").on("click", () => {
          console.log("meinmodal");
          suspendmodal("Simulierter Gast", "Simulierter.Gast", true);
        });
      }
      let free = Math.max(0, siteSettings.kista_koord_guests - r.used - (r.sim == null ? 0 : 1) - (r.simuserhtml == null ? 0 : 1));
      var fsatz;
      switch (free) {
        case 0:
          fsatz = 'Es können keine weiteren Einladungen können versandt werden, da das Limit erreicht ist.';
          break;
        case 1:
          fsatz = free + ' weitere Einladung kann derzeit versandt werden.';
          break;
        case siteSettings.kista_koord_guests:
          fsatz = ' Einladungen können derzeit versandt werden.';
        default:
          fsatz = free + ' weitere Einladungen können derzeit versandt werden.';
      }
      if (free <= 2) {
        fsatz += "<br>Deaktivieren Sie nicht mehr benötigte Gast-Accounts oder löschen Sie überfällige Einladungen, wenn Sie Platz für neue Einladungen schaffen wollen.";
      }
      $('div.directory-table__row[data-tr-username]').attr("data-tr-iscoord", 0);
      r.coords.forEach(c => $('div.directory-table__row[data-tr-username="' + c[1] + '"]').attr({
        "data-tr-iscoord": 1,
        "data-tr-userid": c[0]
      }));
      let activenc = r.noncoords.filter(x => x[5]);
      activenc.forEach(c => $('div.directory-table__row[data-tr-username="' + c[1] + '"]').attr({
        "data-tr-iscoord": 0,
        "data-tr-userid": c[0]
      }));
      $("div.directory-table__row[data-tr-iscoord=0][data-tr-username]").each((i, x) => {
        x = $(x);
        console.log("abc", i, x);
        x.find("div.koorddeactivate").empty().append($(`<button class='kista-koord btn'>Verwalten</button>`).on("click", e => {
          suspendmodal(x.attr("data-tr-userid"), x.attr("data-tr-username"), true);
        }));
      });
      $("div.directory-table__row.simuser").find("button.kista-koord2").on("click", () => {
        console.log("meinmodal");
        suspendmodal("Simulierter Gast", "Simulierter.Gast", true);
      });

      // $('tr[data-tr-iscoord=0] td.koorddeactivate').append($(`<button class='kista-koord btn'>Verwalten</button>`).on("click",));
      let nw = $('<div class="directory-table__row koordtc">').append($('<div class="directory-table__cell" style="grid-column: 1 / 5">' + fsatz + '</div>'));
      $('div.group-members>div.directory-table__body div.directory-table__row.koordtc').remove();
      $('div.group-members>div.directory-table__body').append(nw);
      $('div.group-members>div.directory-table__body div.directory-table__row.koordtt').remove();
      $('div.group-members>div.directory-table__body div.directory-table__row.koordth').remove();
      $('div.group-members>div.directory-table__body div.directory-table__row.koordpendingtr').remove();
      if (r.sim != null && r.pending.filter(x => x[0] == -99).length == 0) {
        r.pending.push([-99, r.sim.email, currentUser.username, r.sim.simulated, new Date(r.sim.expires_at) / 1000]);
        //userhtml([,"test.user",,"Test User",60])
      }
      if (r.pending.length) {
        nw = $('<div class="directory-table__row koordtt">').append($('<div class="directory-table__cell" style="grid-column: 1 / 5">Ausstehende Einladungen</div>'));
        $('div.group-members>div.directory-table__body').append(nw);
        nw = $('<div class="directory-table__row koordth">').append($('<div class="directory-table__cell" data-colspan="2">Email</div><div class="directory-table__cell" data-colspan="2">Eingeladen von</div><div class="directory-table__cell">Datum</div><div data-appendempty="1" class="directory-table__cell">Einlösbar bis</div>'));
        $('div.group-members>div.directory-table__body').append(nw);
        r.pending.forEach(p => {
          nw = $('<div class="directory-table__row koordpendingtr" data-tr-inviteid="' + p[0] + '">').append($('<div data-colspan="2" class="directory-table__cell koordpendingmail">' + p[1] + '</div><div data-colspan="2" class="directory-table__cell">' + p[2] + '</div><div class="directory-table__cell">' + shortdt(p[3]) + '</div><div class="directory-table__cell">' + shortdt(p[4]) + '</div>' /*'<div class="directory-table__cell koordedit"></div>'*/));
          nw.find(".koordedit").append($(`<button class='kista-koord btn'>Verwalten</button>`).on("click", e => {
            manageinvitemodal(p[0], e);
          }));
          $('div.group-members>div.directory-table__body').append(nw);
        });
      }
      let inactivenc = r.noncoords.filter(x => !x[5]);
      if (inactivenc.length || r.simuser && !r.simuser.active) {
        // Inaktive
        nw = $('<div class="directory-table__row koordtt">').append($('<div class="directory-table__cell" style="grid-column: 1 / 5">Inaktive Accounts</div>'));
        $('div.group-members>div.directory-table__body').append(nw);
        nw = $('<div class="directory-table__row koordth">').append($('<div class="directory-table__cell" data-colspan="2">Benutzername</div><div colspan="2"></td>'));
        $('div.group-members>div.directory-table__body').append(nw);
        if (r.simuser && !r.simuser.active) {
          $("div.group-members div.directory-table__row.simuser").remove();
          $('div.group-members>div.directory-table__body').append(simuserhtml2());
          $("tr.simuser.simdeact button").on("click", () => {
            suspendmodal("Simulierter Gast", "Simulierter.Gast", false);
          });
        }
        inactivenc.forEach(i => {
          $('div.directory-table__row[data-tr-username="' + i[1] + '"]').css("display", "none");
          nw = $('<div class="directory-table__row koordinactivetr">').append(userhtml(i));
          nw.find(".koordactivate").append($(`<button class='kista-koord btn'>Verwalten</button>`).on("click", e => {
            suspendmodal(i[0], i[1], false);
          }));
          $('div.group-members>div.directory-table__row').append(nw);
        });
      }
    };
    $.ajax("/kista-koord/list.json", {
      success: r => {
        if (r.full_name) {
          window.xdata.full = r.full_name;
          console.log("!!!!!!!!", r.full_name);
        }
        window.xdata.glist = r;
        console.log("KOORD LIST JSON", r);
        updatelist(r);
        let groupmic = 0,
          groupmi = setInterval(() => {
            $("div.group-members div.directory-table__row:not([data-tr-username])").find("div[data-username]").each((n, x) => {
              // hänge jedem <tr> ein Attribut data-tr-username an (holt den namen aus dem tiefer drinliegenden div)
              $(x).parents("div.directory-table__row").attr("data-tr-username", $(x).attr("data-username"));
              console.log("attached", $(x).attr("data-username"), $(x).parents("div.directory-table__row"));
            });
            if ("glist" in window.xdata) {
              updatelist(window.xdata.glist);
            }
            if (++groupmic >= 20) {
              clearInterval(groupmi);
            }
            console.log("mic count", groupmic);
          }, 100);
      }
    });
  }
  function userhtml(i) {
    return $(`
<div class="directory-table__cell directory-table__cell--username group-member">
 <div data-username="${i[1]}" class="user-info small ember-view">
   <div class="user-image">
    <div class="user-image-inner">
      <a href="/u/${i[1]}" data-user-card="${i[1]}" aria-hidden="true"><img loading="lazy" alt="" width="48" height="48" src="/user_avatar/localhost/${i[1].toLowerCase()}/48/${i[4]}_2.png" class="avatar" title="${i[3] ? i[3] : ""}"></a>
        <div class="ember-view"><div class="avatar-flair avatar-flair-Tester2  avatar-flair-image" style="background-image: url(${window.gdat.group.flair_url}); " title="${window.gdat.group.full_name}"></div></div>
    </div>
   </div>
   <div class="user-detail">
     <div class="name-line"><a href="/u/${i[1]}" data-user-card="${i[1]}"><span class="username">${i[1]}</span><span class="name">${i[1]}</span></a></div>
     <div class="title">${i[3] ? i[3] : ""}</div>
   </div>
 </div>
</div>
<div class="directory-table__cell directory-table__cell--added"><span class="directory-table__label"><span>Hinzugefügt</span></span><span class="directory-table__value"><span></span></span></div>
<div class="directory-table__cell directory-table__cell--last-posted"><span class="directory-table__label"><span>Geschrieben</span></span><span class="directory-table__value"><span></span></span></div>
<div class="directory-table__cell directory-table__cell--last-seen koordactivate"><span class="directory-table__label"><span>Zuletzt gesehen</span></span><span class="directory-table__value"><span></span></span></div>

<td class="avatar koordinactiveuser" colspan="2">
<div style="display: inline-flex">
                <div data-username="` + i[1] + `" class="user-info small ember-view"><div class="user-image">
  <div class="user-image-inner">
    <a href="/u/` + i[1] + `" data-user-card="` + i[1] + `"><img alt="" width="45" height="45" src="` + (i[4] != null ? "/user_avatar/localhost/" + i[1].toLowerCase() + "/45/" + i[4] + "_2.png" : "/letter_avatar_proxy/v4/letter/+/0ea827/45.png") + `" class="avatar" title="` + (i[3] ? i[3] : "") + `" aria-label="` + (i[3] ? i[3] : "") + `"></a>
    <div class="ember-view"><div class="avatar-flair avatar-flair-Tester2  avatar-flair-image" style="background-image: url(` + window.gdat.group.flair_url + `); " title="` + window.gdat.group.full_name + `"></div></div>
  </div>
</div>
`);
  }
  function simuserhtml(i) {
    return $(`
<tr class="simuser simact">
<!---->
              <td class="avatar" colspan="2">
                <div data-username="Simulierter Gast" id="ember837" class="user-info small ember-view"><div class="user-image">
  <div class="user-image-inner">
    <a href="/u/Simulierter.Gast" data-user-card="Simulierter Gast"><img alt="" src="/user_avatar/localhost/test.user/120/60_2.png" class="avatar" title="Simulierter Gast" aria-label="Simulierter Gast" width="45" height="45"></a>
    <div id="ember838" class="ember-view"><div class="avatar-flair avatar-flair-Demo  avatar-flair-image" style="background-image: url(` + window.gdat.group.flair_url + `); " title="Demo"></div></div>
  </div>
</div>

<div class="user-detail">
  <div class="name-line">
    <span class="username bold"><a href="/u/Simulierter.Gast" data-user-card="Simulierter.Gast">Simulierter Gast</a></span>
    <span class="name margin"><a href="/u/Simulierter.Gast" data-user-card="Simulierter.Gast"></a></span>
    <span id="ember839" class="ember-view"><!----></span>
  </div>
  <div class="title"></div>

<!---->
</div>

<span id="ember840" class="ember-view"><!----></span>
</div>
              </td>

              <td class="group-owner">
<!---->                  Primär
              </td>
              <td>
                <span class="text"><span class="nrelative-date date" title="10. Mär. 2022 um 09:35" data-time="1632132662128" data-format="medium">gerade eben</span></span>
              </td>
              <td>
                <span class="text"><!----></span>
              </td>
              <td>
                <span class="text"><span class="nrelative-date date" title="10. Mär. 2022 um 09:37" data-time="1632199066654" data-format="medium">gerade eben</span></span>
              </td>

              <td class="koorddeactivate"><button class="kista-koord2 btn">Verwalten</button></td>
            </tr>
  `);
  }
  function simuserhtml2(i) {
    return $(`
<tr class="simuser simdeact">
<!---->
              <td class="avatar" colspan="2">
                <div data-username="Simulierter Gast" id="ember837" class="user-info small ember-view"><div class="user-image">
  <div class="user-image-inner">
    <a href="/u/Simulierter.Gast" data-user-card="Simulierter Gast"><img alt="" src="/user_avatar/localhost/test.user/120/60_2.png" class="avatar" title="Simulierter Gast" aria-label="Simulierter Gast" width="45" height="45"></a>
    <div id="ember838" class="ember-view"><div class="avatar-flair avatar-flair-Demo  avatar-flair-image" style="background-image: url(` + window.gdat.group.flair_url + `); " title="Demo"></div></div>
  </div>
</div>

<div class="user-detail">
  <div class="name-line">
    <span class="username bold"><a href="/u/Simulierter.Gast" data-user-card="Simulierter.Gast">Simulierter Gast</a></span>
    <span class="name margin"><a href="/u/Simulierter.Gast" data-user-card="Simulierter.Gast"></a></span>
    <span id="ember839" class="ember-view"><!----></span>
  </div>
  <div class="title"></div>

<!---->
</div>

<span id="ember840" class="ember-view"><!----></span>
</div>
              </td>

              <td class="group-owner">
<!---->                  
              </td>
              <td>
                <span class="text"><span class="nrelative-date date" title="10. Mär. 2022 um 09:35" data-time="1632132662128" data-format="medium"></span></span>
              </td>
              <td>
                <span class="text"><!----></span>
              </td>
              <td>
                <span class="text"><span class="nrelative-date date" title="10. Mär. 2022 um 09:37" data-time="1632199066654" data-format="medium"></span></span>
              </td>

              <td class="koorddeactivate"><button class="kista-koord2 btn">Verwalten</button></td>
            </tr>
  `);
  }
  function initializeKistaKoord(api) {
    // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/lib/plugin-api.js.es6
    // https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/app/lib/plugin-api.js

    const currentUser = api.getCurrentUser();
    const siteSettings = api.container.lookup("site-settings:main");
    console.log(currentUser);
    console.log(siteSettings);
    if (currentUser && currentUser.hasOwnProperty("primary_group_name") && currentUser.primary_group_name) {
      console.log(currentUser.primary_group_id,
      //#52
      currentUser.primary_group_name,
      //"Devs"
      currentUser.groups,
      //[1]={id:52: name="Devs"}
      currentUser.title //"Entwickler"
      );
      $.ajax({
        url: `/g/${currentUser.primary_group_name}.json`,
        async: false,
        success: data => {
          const visibleforgroupnums = siteSettings.kista_koord_visible_for.split("|").filter(x => x.length).map(Number).map(Number);
          if (visibleforgroupnums.length == 0 || amInGroupNums(visibleforgroupnums)) {
            window.gdat = data;

            // const { iconNode } = require("discourse-common/lib/icon-library");
            const {
              avatarImg
            } = require("discourse/widgets/post");
            const {
              addExtraUserClasses
            } = require("discourse/helpers/user-avatar");
            api.decorateWidget('header-icons:after', helper => {
              return helper.h('li', [helper.h('a#group-button.icon', {
                href: "/g/" + currentUser.primary_group_name
                //,title: data.group.full_name
              }, /*iconNode('arrow-circle-up')*/
              avatarImg("medium", Object.assign({
                alt: data.group.full_name
              }, addExtraUserClasses(api.getCurrentUser(), {
                template: data.group.flair_url,
                username: data.group.full_name
              }))))]);
            });

            // api.createWidget("kista-group", {
            //   tagName: "li.header-dropdown-toggle",
            //   html(arg) {
            //     return h("div.icon.btn-flat", this.attach("button", { title: data.group.full_name, url:"/g/"+currentUser.primary_group_name, className: "kista-group-button"}));
            //   } //route: "discovery.latest"
            //   // https://meta.discourse.org/t/add-custom-menu-panel-to-the-correct-location-same-as-hamburger-menu-panel/119364/4
            // });
            // api.decorateWidget("header-icons:after", function(helper) { //https://github.com/discourse/discourse/blob/master/app/assets/javascripts/discourse/app/widgets/header.js#L199
            //   return helper.attach("kista-group", "x", {formFactor: "widget"});
            // });
            // setInterval(n=>{$(".kista-group-button:not(.groupcssed)").css("background-image",'url('+data.group.flair_url+')').addClass("groupcssed")},500);
          }
        }
      });
    }

    /*
    api.createWidget('group-link', {
        tagName: 'div.group-panel',
    
        settings: {
            maxWidth: 320
        },
    
        panelContents() {
            return h(
                'ul.group-link',
                menuItems.map(item => h('li', h('a', { href: item.href }, item.text)))
            );
        },
    
        html() {
            return this.attach('groupmenu-panel', {
                contents: () => this.panelContents(),
                maxWidth: this.settings.maxWidth
            });
        },
    
    });
    */

    const groupct = api.container.lookup("controller:group");
    console.log("groupct", groupct);
    $(".create-topics-notice").remove();
    let target = $("div.group-members-manage");
    let ndiv = $("<div>").text("jo");
    target.append(ndiv);
    let amInGroupNums = a => currentUser && currentUser.groups.filter(x => a.indexOf(x.id) > -1).length > 0;
    api.onPageChange((url, title) => {
      // const siteSettings = api.container.lookup("site-settings:main");
      console.log('koord: ' + url + ' t ' + title); // /g/Dinslaken t Mitglieder - Dinslaken - LernnetzwApp
      let grouprx = /^\/g\/([^\/#?&;]+)/.exec(url);
      //[41,52,140,142]; //41 Koord 52 Devs 140 Sondergruppen 54 Tester 142 Tester2
      const visibleforgroupnums = siteSettings.kista_koord_visible_for.split("|").filter(x => x.length).map(Number).map(Number);
      if (grouprx && decodeURI(grouprx[1]) == currentUser.primary_group_name && amInGroupNums([41]) && (visibleforgroupnums.length == 0 || amInGroupNums(visibleforgroupnums))) {
        console.log(url, grouprx, currentUser.primary_group_name, amInGroupNums([41]), visibleforgroupnums);
        console.log("Gruppenadministration");
        groupcoord(api);
      }
    });
    api.onAppEvent('inserted-custom-html', () => {
      console.log('koord: footer rendered');
    });
    //api.createWidget("koordnd", args);
    //api.reopenWidget(name, args)
  }
  var _default = _exports.default = {
    name: "kista-koord",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeKistaKoord);
    }
  };
});